export const sample = [
  {
    name: "NK SFS RSPDR BKPK",
    code: "BA4886-005",
    group: "Bag",
    status: "Approved",
    silhouette: "BACKPACK",
    importCountry: "US"
  },
  {
    name: "TEAM U NK H86 CAP",
    code: "102699-739",
    group: "Headwear",
    status: "Approved",
    silhouette: "CAP/HAT/VISOR",
    importCountry: "US"
  },
  {
    name: "NIKE M ESSENTIAL FG",
    code: "AC4230-057",
    group: "Glove",
    status: "Approved",
    silhouette: "GLOVES",
    importCountry: "US"
  },
  {
    name: "NIKE W GYM ELEMENTAL FG",
    code: "AC4237-010",
    group: "Glove",
    status: "Approved",
    silhouette: "GLOVES",
    importCountry: "US"
  },
  {
    name: "NIKE HYPERFUEL BOTTLE 24 OZ",
    code: "AC3893-084",
    group: "Water Bottle",
    status: "Approved",
    silhouette: "OTHER ACCESSORIES",
    importCountry: "US"
  },
  {
    name: "NR BRSLA GMSK – 9.0 (23L)",
    code: "BA5953-230",
    group: "Bag",
    status: "Approved",
    silhouette: "GYM SACK",
    importCountry: "US"
  },
  {
    name: "NK CLUB TEAM ROLLER OLY 2022",
    code: "DH5195-492",
    group: "Bag",
    status: "Approved",
    silhouette: "DUFFEL GRIP DRUM",
    importCountry: "US"
  },
  {
    name: "NK MASTERY YOGA MAT 5 MM LONG",
    code: "DH3262-001",
    group: "Sport Fitness",
    status: "Approved",
    silhouette: "OTHER ACCESSORIES",
    importCountry: "US"
  },
  {
    name: "NK AIR SMIT – SM NFS",
    code: "D08317-010",
    group: "Bag",
    status: "Approved",
    silhouette: "SMALL ITEMS WAISTPACKS",
    importCountry: "US"
  },
  {
    name: "NK SFS RSPDR BKPK",
    code: "BA4886-005",
    group: "Bag",
    status: "Approved",
    silhouette: "BACKPACK",
    importCountry: "US"
  },
  {
    name: "TEAM U NK H86 CAP",
    code: "102699-739",
    group: "Headwear",
    status: "Approved",
    silhouette: "CAP/HAT/VISOR",
    importCountry: "US"
  },
  {
    name: "NIKE M ESSENTIAL FG",
    code: "AC4230-057",
    group: "Glove",
    status: "Approved",
    silhouette: "GLOVES",
    importCountry: "US"
  },
  {
    name: "NIKE W GYM ELEMENTAL FG",
    code: "AC4237-010",
    group: "Glove",
    status: "Approved",
    silhouette: "GLOVES",
    importCountry: "US"
  },
  {
    name: "NIKE HYPERFUEL BOTTLE 24 OZ",
    code: "AC3893-084",
    group: "Water Bottle",
    status: "Approved",
    silhouette: "OTHER ACCESSORIES",
    importCountry: "US"
  },
  {
    name: "NR BRSLA GMSK – 9.0 (23L)",
    code: "BA5953-230",
    group: "Bag",
    status: "Approved",
    silhouette: "GYM SACK",
    importCountry: "US"
  },
  {
    name: "NK CLUB TEAM ROLLER OLY 2022",
    code: "DH5195-492",
    group: "Bag",
    status: "Approved",
    silhouette: "DUFFEL GRIP DRUM",
    importCountry: "US"
  },
  {
    name: "NK MASTERY YOGA MAT 5 MM LONG",
    code: "DH3262-001",
    group: "Sport Fitness",
    status: "Approved",
    silhouette: "OTHER ACCESSORIES",
    importCountry: "US"
  },
  {
    name: "NK AIR SMIT – SM NFS",
    code: "D08317-010",
    group: "Bag",
    status: "Approved",
    silhouette: "SMALL ITEMS WAISTPACKS",
    importCountry: "US"
  },
  {
    name: "NK SFS RSPDR BKPK",
    code: "BA4886-005",
    group: "Bag",
    status: "Approved",
    silhouette: "BACKPACK",
    importCountry: "US"
  },
  {
    name: "TEAM U NK H86 CAP",
    code: "102699-739",
    group: "Headwear",
    status: "Approved",
    silhouette: "CAP/HAT/VISOR",
    importCountry: "US"
  },
  {
    name: "NIKE M ESSENTIAL FG",
    code: "AC4230-057",
    group: "Glove",
    status: "Approved",
    silhouette: "GLOVES",
    importCountry: "US"
  },
  {
    name: "NIKE W GYM ELEMENTAL FG",
    code: "AC4237-010",
    group: "Glove",
    status: "Approved",
    silhouette: "GLOVES",
    importCountry: "US"
  },
  {
    name: "NIKE HYPERFUEL BOTTLE 24 OZ",
    code: "AC3893-084",
    group: "Water Bottle",
    status: "Approved",
    silhouette: "OTHER ACCESSORIES",
    importCountry: "US"
  },
  {
    name: "NR BRSLA GMSK – 9.0 (23L)",
    code: "BA5953-230",
    group: "Bag",
    status: "Approved",
    silhouette: "GYM SACK",
    importCountry: "US"
  },
  {
    name: "NK CLUB TEAM ROLLER OLY 2022",
    code: "DH5195-492",
    group: "Bag",
    status: "Approved",
    silhouette: "DUFFEL GRIP DRUM",
    importCountry: "US"
  },
  {
    name: "NK MASTERY YOGA MAT 5 MM LONG",
    code: "DH3262-001",
    group: "Sport Fitness",
    status: "Approved",
    silhouette: "OTHER ACCESSORIES",
    importCountry: "US"
  },
  {
    name: "NK AIR SMIT – SM NFS",
    code: "D08317-010",
    group: "Bag",
    status: "Approved",
    silhouette: "SMALL ITEMS WAISTPACKS",
    importCountry: "US"
  },
  {
    name: "NK SFS RSPDR BKPK",
    code: "BA4886-005",
    group: "Bag",
    status: "Approved",
    silhouette: "BACKPACK",
    importCountry: "US"
  },
  {
    name: "TEAM U NK H86 CAP",
    code: "102699-739",
    group: "Headwear",
    status: "Approved",
    silhouette: "CAP/HAT/VISOR",
    importCountry: "US"
  },
  {
    name: "NIKE M ESSENTIAL FG",
    code: "AC4230-057",
    group: "Glove",
    status: "Approved",
    silhouette: "GLOVES",
    importCountry: "US"
  },
  {
    name: "NIKE W GYM ELEMENTAL FG",
    code: "AC4237-010",
    group: "Glove",
    status: "Approved",
    silhouette: "GLOVES",
    importCountry: "US"
  },
  {
    name: "NIKE HYPERFUEL BOTTLE 24 OZ",
    code: "AC3893-084",
    group: "Water Bottle",
    status: "Approved",
    silhouette: "OTHER ACCESSORIES",
    importCountry: "US"
  },
  {
    name: "NR BRSLA GMSK – 9.0 (23L)",
    code: "BA5953-230",
    group: "Bag",
    status: "Approved",
    silhouette: "GYM SACK",
    importCountry: "US"
  },
  {
    name: "NK CLUB TEAM ROLLER OLY 2022",
    code: "DH5195-492",
    group: "Bag",
    status: "Approved",
    silhouette: "DUFFEL GRIP DRUM",
    importCountry: "US"
  },
  {
    name: "NK MASTERY YOGA MAT 5 MM LONG",
    code: "DH3262-001",
    group: "Sport Fitness",
    status: "Approved",
    silhouette: "OTHER ACCESSORIES",
    importCountry: "US"
  },
  {
    name: "NK AIR SMIT – SM NFS",
    code: "D08317-010",
    group: "Bag",
    status: "Approved",
    silhouette: "SMALL ITEMS WAISTPACKS",
    importCountry: "US"
  }
]