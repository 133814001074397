import React, { useContext, useEffect } from 'react';

import { Typography, VariantType } from '@nike/admin-design-system-components/dist/index';
import { AppContext, useAppContext } from '../app/utils/context';

import { Restricted } from '../app/components/Restricted';

const Page = () => {
  const { appProps } = useAppContext();
  const { setAppProps } = useContext(AppContext);
  
  useEffect(() => {
    setAppProps({...appProps, section: 'Classification'});
  }, []);

  if (!appProps.permissions?.classification) {
    return <Restricted />;
  }

  return <>
    <Typography variant={VariantType.display1}>Classification</Typography>
  </>
};

export default Page;