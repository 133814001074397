import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { capitalize, fuzzyMatch, isNilOrEmpty, isNotNilOrEmpty } from '@paravano/utils';
import { Button, Chip, ChipGroup, Modal, Popover, Radio, Snack, Snackbar, Text, TextButton, TextLink, Toggle } from "@nike/eds";
import { TextField } from '@nike/admin-design-system-components';
import { Calendar, Ellipsis, Search } from '@nike/nike-design-system-icons';

import { AppContext, useAppContext } from '../app/utils/context';
import { Restricted } from '../app/components/Restricted';
import { SidePanel } from '../app/components/SidePanel';
import { Accordion } from '../app/components/Accordion';
import { Table } from '../app/components/DataTable';

import { sample } from './data.js';

const modalCss = css({
  width: 400,
  '.eds-inputs__radio': {
    marginLeft: 0,
    label: {
      marginTop: 2,
    },
  },
  p: {
    color: '#6a6a6a',
  },
});

const errorCss = css({
  color: 'var(--eds-color-text-danger) !important',
});

const Page = () => {
  const { t } = useTranslation();
  const { appProps } = useAppContext();
  const { setAppProps } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [query, setQuery] = useState('');
  const [showAction, setShowAction] = useState('');
  const [cardView, setCardView] = useState(JSON.parse(localStorage.getItem('cardView')) ?? false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [clearSelected, setClearSelected] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const [showSaveFilters, setShowSaveFilters] = useState(false);
  const [showLoadFilters, setShowLoadFilters] = useState(false);
  const [showSaveFilterConfirmation, setShowSaveFilterConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const testFilters = [
    {
      name: 'Test 1',
      value: 'testing'
    },
    {
      name: 'Test 2',
      value: 'testing'
    },
    {
      name: 'Test 3',
      value: 'testing'
    },
    {
      name: 'Test 4',
      value: 'testing'
    },
    {
      name: 'Test 5',
      value: 'testing'
    },
  ];
  
  const columns = [
    {
      cell: row => <div className="product">
        <div className="product-image">
          <img alt="" src="https://static.nike.com/a/images/t_PDP_1280_v1/f_auto,q_auto:eco/c2cddde1-362e-4710-995d-36f57030107a/elemental-backpack-14wb17.png" height="800" width="800" />
        </div>
        <TextLink>{row.code}</TextLink>
      </div>,
      grow: 2,
      name: t('Product Code'),
      selector: row => row.code,
      sortable: true,
    },
    {
      cell: row => <p>{capitalize(row.name.toLowerCase())}</p>,
      grow: 2,
      name: t('Product Name'),
      selector: row => row.name,
      sortable: true,
    },
    {
      cell: row => <p>{capitalize(row.group.toLowerCase())}</p>,
      name: t('Product Group'),
      selector: row => row.group,
      sortable: true,
    },
    {
      cell: row => <p>{capitalize(row.status.toLowerCase())}</p>,
      name: t('Status'),
      selector: row => row.status,
      sortable: true,
    },
    {
      cell: row => <p>{capitalize(row.silhouette.toLowerCase())}</p>,
      name: t('Silhouette'),
      selector: row => row.silhouette,
      sortable: true,
    },
    {
      cell: row => <p>{row.importCountry}</p>,
      name: t('Import Country'),
      selector: row => row.importCountry,
      sortable: true,
    },
    {
      cell: row => <Ellipsis onClick={() => setShowAction(row)} />,
      // cell: row => <Popover
      //   onClose={() => setShowAction('')}
      //   isOpen={showAction.contains(row)}
      //   // headerSlot={header}
      //   // bodySlot={body}
      //   // footerSlot={footer}
      //   >
      //   <Ellipsis onClick={() => setShowAction(row)} />
      // </Popover>,
      center: true,
      maxWidth: '40px',
      name: t('Actions'),
    },
  ];
  
  useEffect(() => {
    setAppProps({...appProps, section: 'Attributes'});
    setData(sample);
  }, []);

  if (!appProps.permissions?.attributes) {
    return <Restricted />;
  }

  const onSearch = value => {
    setClearSelected(!clearSelected);
    setSelected([]);

    if (isNilOrEmpty(value)) {
      setFilteredData([]);
      setQuery(value);
      return;
    }

    // Faking the loader for now
    setLoading(true);

    const _value = value.toLowerCase();
    const filtered = data.filter(item => fuzzyMatch(item.code, _value) || fuzzyMatch(item.name, _value));

    setFilteredData(filtered);
    setQuery(value);

    // Faking the loader for now
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };


  const onSaveFilter = () => {
    const input = document.getElementById('save-filter');
    const name = input.value;
    const savedFilters = JSON.parse(localStorage.getItem('savedFilters')) ?? [];

    if (isNilOrEmpty(name)) {
      setErrorMessage('Please enter a name.');
      return null;
    }

    if (savedFilters.filter(e => e.name === name).length > 0) {
      setErrorMessage('Please choose a filter name that does not already exist.');
      return null;
    }

    localStorage.setItem('savedFilters', JSON.stringify([...savedFilters, { name, filters }]));
    setShowSaveFilters(false);
    setShowSaveFilterConfirmation(true);
    input.value = '';
  };

  const onCancelSaveFilters = () => {
    document.getElementById('save-filter').value = '';
    setShowSaveFilters(false);
    setErrorMessage('');
  };

  const onLoadFilter = () => {
    const radio = document.querySelector('input[name="saved-filters"]:checked');

    if (isNilOrEmpty(radio?.value)) {
      setErrorMessage('Please choose a filter.');
      return null;
    }

    setFilters(JSON.parse(radio?.value));
    setShowLoadFilters(false);
    radio.checked = false;
  };

  const onCancelLoadFilters = () => {
    const radio = document.querySelector('input[name="saved-filters"]:checked');

    setShowLoadFilters(false);
    setErrorMessage('');

    if (isNotNilOrEmpty(radio)) {
      radio.checked = false;
    }
  };

  const onClearFilters = () => {
    setFilters([]);
  };

  const onApplyFilters = () => {
    setShowFilters(false);
    setFilters(testFilters);
  };

  const onCancelFilters = () => {
    setShowFilters(false);
  };

  const renderSaveFilterConfirmation = () => {
    setTimeout(() => setShowSaveFilterConfirmation(false), 3000);

    return <Snackbar>
      <Snack status="success">
        {t('Your filter has been saved sucessfully.')}
      </Snack>
    </Snackbar>
  };

  const renderError = () => <p className={`eds-type--body-3 eds-spacing--mb-16 ${errorCss}`}>{t(errorMessage)}</p>

  const onChangeView = () => {
    localStorage.setItem('cardView', !cardView);
    setCardView(!cardView);
  }

  return <>
    <div className="d-sm-flx flx-ai-sm-c eds-spacing--mb-40">
      <Text className="flx-gro-sm-1" font={"title-2"} as={"h1"}>{t('Product Attributes')}</Text>
      <div className="d-sm-flx flx-ai-sm-c">
        <Toggle checked={cardView} className="eds-spacing--mr-32" label="Card View" onClick={() => onChangeView()} />
        <Button>{t('Create Attributes')}</Button>
      </div>
    </div>
    <Text className="eds-spacing--mb-16" font={"title-4"} as={"h2"}>{t('Search and Filter')}</Text>
    <div className="eds-spacing--mb-40">
      <div className="d-sm-flx flx-ai-sm-c">
        <div id="product-attributes-search-wrapper" className="flx-gro-sm-1 eds-spacing--mr-32">
          <TextField onChange={e => onSearch(e.target.value)} placeholder={t('Search by Product Code, Base Style Number or Product Name')} startAdornment={<Search />} value={query} />
        </div>
        <div>
          <Button className="flx-gro-sm-1 eds-spacing--mr-16" onClick={() => setShowFilters(true)} variant="secondary">{t('Select Filters')}</Button>
          {isNotNilOrEmpty(localStorage.getItem('savedFilters')) && <TextButton onClick={() => setShowLoadFilters(true)} variant="secondary">{t('Load Filters')}</TextButton>}
        </div>
      </div>
      {isNotNilOrEmpty(filters) && <div className="d-sm-flx flx-ai-sm-c eds-spacing--mt-16">
        <div className="eds-spacing--mr-8">
          <h4 className="eds-type--subtitle-1">{t('Filters')} ({filters.length})</h4>
          <p className="eds-type--subtitle-2"><TextLink onClick={() => onClearFilters()}>{t('Clear')}</TextLink> | <TextLink onClick={() => setShowSaveFilters(true)}>{t('Save')}</TextLink></p>
        </div>
        <ChipGroup>
          {filters.map((filter, i) => <Chip key={`filter=${i}`} label={`${filter.name}: ${filter.value}`} onClose={() => setFilters(filters.filter(item => item.name != filter.name))} />)}
        </ChipGroup>
      </div>}
    </div>
    <Text className="eds-spacing--mb-16" font={"title-4"} as={"h2"}>{t('Search Results')}</Text>
    {isNotNilOrEmpty(filteredData) && <div className="d-sm-flx flx-ai-sm-c eds-spacing--mb-16">
      <div className="flx-gro-sm-1">
        <TextLink>{t('Export')} {isNotNilOrEmpty(selected) ? selected.length : 'All'} {t('Items')}</TextLink>
        {isNotNilOrEmpty(selected) && <span> | <TextLink onClick={() => {setClearSelected(!clearSelected); setSelected([])}}>{t('Deselect All')}</TextLink></span>}
      </div>
      <p>{filteredData.length} {t('Items Found')}</p>
    </div>}
    <Table cards={cardView} clear={clearSelected} columns={columns} data={filteredData} loading={loading} onSelection={e => setSelected(e.selectedRows)} splash={isNilOrEmpty(query)} />
    <SidePanel
      footerSlot={() => <>
        <Button className="eds-spacing--mr-12" onClick={() => onApplyFilters()}>{t('Apply')}</Button>
        <Button onClick={() => onCancelFilters()} variant="secondary">{t('Cancel')}</Button>
      </>}
      headerSlot={() => <h3 className="eds-type--title-3">{t('Select Filters')}</h3>}
      isOpen={showFilters}
      onDismiss={() => onCancelFilters()}>
        <Accordion
          className="eds-spacing--mb-16"
          headerSlot={<Text font={"title-5"} as={"h3"}>{t('Purchase Order')}</Text>}
          id="accordion-purchase-order"
          onChange={(e) => console.log(e)}>
          <TextField color="secondary" onChange={e => onSearch(e.target.value)} endAdornment={<Calendar />} />
        </Accordion>
        <Accordion
          className="eds-spacing--mb-16"
          headerSlot={<Text font={"title-5"} as={"h3"}>{t('Product')}</Text>}
          id="accordion-product"
          onChange={(e) => console.log(e)}>
          <TextField color="secondary" onChange={e => onSearch(e.target.value)} endAdornment={<Calendar />} />
        </Accordion>
        <Accordion
          className="eds-spacing--mb-16"
          headerSlot={<Text font={"title-5"} as={"h3"}>{t('Sample List')}</Text>}
          id="accordion-sample-list"
          onChange={(e) => console.log(e)}>
          <TextField color="secondary" onChange={e => onSearch(e.target.value)} endAdornment={<Calendar />} />
        </Accordion>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non porta orci. Nulla semper, ante in consequat laoreet, enim ex convallis tellus, et vestibulum est turpis non est. Curabitur tristique eros eget ornare euismod. Vestibulum a aliquam mi. Quisque lobortis ligula lacus, vel aliquet diam lacinia nec. Morbi eros lacus, vulputate sit amet iaculis quis, fringilla id mi. Aliquam sit amet quam orci. Pellentesque nisi mi, porta in malesuada sit amet, aliquet a magna. Maecenas rhoncus, erat et vehicula tempus, dui sapien faucibus nibh, luctus dapibus massa dolor a erat. Integer ex diam, fringilla sit amet diam nec, suscipit egestas ligula. Mauris ac lorem vulputate, iaculis eros in, viverra odio. Cras hendrerit efficitur augue, sit amet placerat odio cursus et. Nunc velit sem, euismod id enim ac, lacinia vestibulum ligula. Maecenas porttitor iaculis porta. In nec commodo dolor. Nullam congue neque nunc, eget tempus lacus rhoncus eget.

Sed at ex dui. Maecenas pellentesque diam quis mauris viverra malesuada. Curabitur quis auctor enim, ut tempor velit. Donec a elit eget ligula tristique mollis. Curabitur vel leo suscipit, pharetra eros condimentum, porta mi. Quisque nec ultricies diam. Quisque eget ultrices risus, at suscipit justo. Sed consectetur a ipsum ac laoreet. Vivamus dictum aliquam massa, ac pulvinar ante viverra eu. Duis in lobortis eros. Suspendisse dictum, neque et condimentum accumsan, sem purus porttitor dui, nec commodo massa tortor id est. Duis sagittis lectus consequat, pharetra turpis a, viverra velit.

Aliquam fringilla risus in ligula vehicula, eu venenatis nulla ultrices. Phasellus ullamcorper iaculis faucibus. Aenean mi diam, congue dignissim semper nec, fringilla quis nisl. Nulla vitae luctus turpis, nec iaculis ipsum. Praesent sollicitudin vehicula nibh, vel consectetur erat gravida vitae. Sed aliquam convallis velit, sit amet bibendum augue elementum et. Quisque a dui sit amet tellus porta iaculis sit amet et erat. Nullam ac justo nisi. Cras quis scelerisque sapien. Praesent arcu lectus, dictum quis justo id, tincidunt accumsan nisl. Donec odio tortor, iaculis eget viverra eu, euismod ut lacus. Phasellus ut est imperdiet arcu tincidunt aliquet. In lacinia vel magna non blandit. Proin lacinia felis id velit sollicitudin mollis. In consectetur lacus odio, a consequat lectus pretium ac. Curabitur pharetra ullamcorper bibendum.

Quisque velit mi, consequat vel eros at, scelerisque feugiat est. Sed euismod non tortor vitae sodales. Donec scelerisque, augue pulvinar tempus hendrerit, nibh felis tempus enim, in ultrices dui arcu volutpat metus. Curabitur et tortor lorem. In bibendum dictum mollis. Curabitur diam nunc, viverra ac tortor feugiat, feugiat egestas odio. Nulla non ex rutrum, lobortis ante quis, eleifend orci. In auctor aliquet est quis rutrum. Pellentesque molestie ullamcorper rutrum. Integer sodales metus ipsum, id auctor erat convallis ut. Nullam luctus lectus in augue tristique hendrerit.

Integer eros ligula, interdum vitae massa vitae, auctor hendrerit elit. Nunc nec elit a turpis aliquet dictum id mattis urna. Maecenas ac scelerisque lectus. Quisque accumsan dolor eu justo tempor, iaculis fringilla nunc imperdiet. Cras nibh nulla, pharetra et mi fringilla, vestibulum cursus enim. Cras vel nisi ac leo faucibus finibus eget id tortor. Ut id tristique ante. Nam fringilla ex sapien, suscipit varius dolor hendrerit vel. In convallis vulputate est, eu interdum augue lobortis nec.</p>
    </SidePanel>
    <Modal
      isOpen={showSaveFilters}
      onDismiss={() => onCancelSaveFilters()}
      headerSlot={<Text font={"title-3"} as={"h3"}>{t('Save Filter')}</Text>}
      footerSlot={<>
        <Button className="eds-spacing--mr-12" onClick={() => onSaveFilter()} size="small">{t('Save')}</Button>
        <Button onClick={() => onCancelSaveFilters()} size="small" variant="secondary">{t('Cancel')}</Button>
      </>}>
        <div className={modalCss}>
          <TextField className="eds-spacing--mb-16" color="secondary" id="save-filter" placeholder={t('Filter Name')} />
          {isNotNilOrEmpty(errorMessage) && renderError()}
          <p className="eds-type--body-3">{filters.map((filter, i) => `${filter.name}: ${filter.value}${i < (filters.length - 1) ? ', ' : ''}`)}</p>
        </div>
    </Modal>
    <Modal
      isOpen={showLoadFilters}
      onDismiss={() => onCancelLoadFilters()}
      headerSlot={<Text font={"title-3"} as={"h3"}>{t('Load Filter')}</Text>}
      footerSlot={<>
        <Button className="eds-spacing--mr-12" onClick={() => onLoadFilter()} size="small">{t('Load')}</Button>
        <Button onClick={() => onCancelLoadFilters()} size="small" variant="secondary">{t('Cancel')}</Button>
      </>}>
        <div className={modalCss}>
          {isNotNilOrEmpty(errorMessage) && renderError()}
          {JSON.parse(localStorage.getItem('savedFilters'))?.map((filters, i) => <div className="eds-spacing--mb-16" key={`load-filter-${i}`}>
            <Radio name="saved-filters" label={filters.name} value={JSON.stringify(filters.filters)} />
            <p className="eds-type--body-3 eds-spacing--ml-36">{filters.filters.map((filter, i) => `${filter.name}: ${filter.value}${i < (filters.filters.length - 1) ? ', ' : ''}`)}</p>
          </div>)}
        </div>
    </Modal>
    {showSaveFilterConfirmation && renderSaveFilterConfirmation()}
  </>
};

export default Page;