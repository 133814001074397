import React from 'react';
import { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import { css } from '@emotion/css';
import { AegisClient, useAuth } from "@nike/aegis-auth-react";
import { isNotNilOrEmpty } from '@paravano/utils';
import { useTranslation } from 'react-i18next';

import { setGlobalHeader } from "./app/utils/http";
import { AppContext } from './app/utils/context';
import setPermissions from './app/utils/permissions';

import { AdminDesignSystemProvider, Layout, Tooltip, setActiveValueInNavItems } from '@nike/admin-design-system-components';
import { Avatar } from "@nike/eds";
import {
  Global,
  Settings,
  Shoe,
} from '@nike/nike-design-system-icons';

import config from "./app/config/config.js";
import Routes from './Routes';
import { Restricted } from './app/components/Restricted';
import './app/localization';

import "@nike/eds/dist/index.css";
import './app/shared/styles.css';

const headerCss = css({
  color: 'black',
  fontSize: 18,
  fontWeight: 700,
  whiteSpace: 'nowrap',
});

const tooltipCss = css({
  marginRight: -16,
  paddingRight: 16,
});

const navItems = [
  {
    value: 'attributes',
    label: 'Attributes',
    icon: <Shoe />,
  },
  {
    value: 'classification',
    label: 'Classification',
    icon: <Global />,
  },
  {
    value: 'settings',
    label: 'Settings (QA Only)',
    icon: <Settings />,
  },
];

function App() {
  const { t } = useTranslation();
  const history = useHistory();
  const client = new AegisClient({
    qa: !config.isProd,
    ...config.oAuth,
  });
  const { hasInitStarted, loginComplete } = useAuth(client);
  const [appProps, setAppProps] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [nav, setNav] = useState(navItems);
  const title = `${t('Nike TradeNet')}${isNotNilOrEmpty(appProps.section) ? ' | ' + appProps.section : ''}`;

  useEffect(() => {
    const handleLogin = ({ accessToken, idToken }) => {
      setGlobalHeader("Authorization", `Bearer ${accessToken.accessToken}`);
      setAppProps({ token: accessToken.accessToken, email: idToken.claims.email, name: idToken.claims.name, permissions: setPermissions(idToken.claims.groups), group: idToken.claims.groups });
    };

    client.on("login:success", handleLogin);
    client.on("renew:access:success", handleLogin);
  });

  const handleHome = () => {
    setNav(setActiveValueInNavItems(null, navItems));
    history.push('/');
  };

  const handleNavigation = e => {
    setNav(setActiveValueInNavItems(e.value, navItems));
    history.push(e.value);
  };
  
  if (!hasInitStarted) return null;

  return (
    <AppContext.Provider value={{appProps, setAppProps}}>
      <Helmet title={title} />
      <AdminDesignSystemProvider>
        <Layout
          content={!loginComplete ? <Restricted /> : <Switch>{Routes.map((x, i) => <Route key={i} {...x} />)}</Switch>}
          enableNavPanel={true}
          enableNavLogoMenuOnMouseEnter={false}
          headerLeft={<div className={headerCss}>{t('Nike TradeNet')}</div>}
          headerRight={isNotNilOrEmpty(appProps.name) && <div className={headerCss}>
            {/* <Popover
              onClose={() => setShowTooltip(true)}
              isOpen={showTooltip}
              placement="top-start"
              bodySlot={<p style={{ wordBreak: 'break-word' }}>{appProps?.group.map(e => <>{e}<br /></>)}</p>}>
              <div className={tooltipCss} onClick={() => setShowTooltip(true)}>
                <Avatar initials={ appProps?.name?.split(' ')[0].charAt(0) + appProps?.name?.split(' ')[1].charAt(0) } />
              </div>
            </Popover> */}
            <Tooltip
              body={<p style={{ wordBreak: 'break-word' }}>{appProps?.group.map((e, i) => <span key={`role-${i}`}>{e}<br /></span>)}</p>}
              open={showTooltip}
              placement="bottom-end"
              title={appProps?.name}>
                <div className={tooltipCss} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                  <Avatar initials={ appProps?.name?.split(' ')[0].charAt(0) + appProps?.name?.split(' ')[1].charAt(0) } />
                </div>
            </Tooltip>
          </div>}
          navItems={nav}
          navProductName={t('Nike TradeNet')}
          onClickLogo={() => handleHome()}
          onNavigate={(e) => handleNavigation(e)} />
      </AdminDesignSystemProvider>
    </AppContext.Provider>
  )
}

export default App;
