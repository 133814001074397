import { removeKeys } from '@paravano/utils';

const setPermissions = (groups) => {
  const group = groups.toString();
  const admin = group.includes('Trade.Tech.ADMIN');
  let permissions = {}

  permissions = {
    attributes: 'read',
    classification: 'read',
  }

  if (group.includes('Trade.Product.Attribute.READ')) {
    permissions = {
      ...permissions,
      materialContent: 'read',
    }
  };
  
  if (group.includes('Trade.Product.Attribute.EDIT')) {
    permissions = {
      ...permissions,
      attributes: 'edit',
    }
  };

  if (group.includes('Trade.Product.Classification.EDIT')) {
    permissions = {
      ...permissions,
      classification: 'edit',
    }
  };

  if (group.includes('Trade.MasterData.Specialist')) {
    permissions = {
      ...permissions,
      masterData: 'edit',
      tradeContent: 'edit',
    }
  };

  if (group.includes('Trade.Product.Label.Edit')) {
    permissions = removeKeys(permissions, ['classification']);
    permissions = {
      ...permissions,
      materialContent: 'edit',
    }
  };

  if (admin) {
    permissions = {
      ...permissions,
      attributes: 'edit',
      classification: 'edit',
      masterData: 'edit',
      materialContent: 'edit',
      tradeContent: 'edit',
      userAdmin: 'edit',
    }
  };

  return permissions;
}

export default setPermissions;