import React, { useContext, useEffect } from 'react';

import { Typography, VariantType } from '@nike/admin-design-system-components/dist/index';
import { AppContext, useAppContext } from '../app/utils/context';

import { Restricted } from '../app/components/Restricted';

const Page = () => {
  const { appProps } = useAppContext();
  const { setAppProps } = useContext(AppContext);
  
  useEffect(() => {
    setAppProps({...appProps, section: 'Settings'});
  }, []);

  if (!appProps.permissions?.userAdmin) {
    return <>
      <Restricted />
      <p>This is a test page to verifiy user permissions. You can only see it if you're an admin. You are not an admin.</p>
    </>;
  }

  return <>
    <Typography variant={VariantType.display1}>Admin Settings</Typography>
    <p>This is a test page to verifiy user permissions. You can only see it if you're an admin. You are an admin.</p>
  </>;
};

export default Page;