import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css, cx } from '@emotion/css'
import DataTable from 'react-data-table-component';

import {
  CaretDown,
  CaretLeft,
  CaretRight,
} from '@nike/nike-design-system-icons';
import { Button, Checkbox, Spinner, Text } from "@nike/eds";

export const Table = ({cards, clear, columns, data, loading, onSelection, splash}) => {
  const { t } = useTranslation();

  const emptyStyles = css({
    textAlign: 'center',
  });

  const tableWrapperStyles = css({
    '.rdt_Table': {
      borderRadius: 8,
      overflow: 'hidden',
      transition: 'all 0.25s ease',
      '.rdt_TableBody': {
        transition: 'all 0.25s ease',
        '.rdt_TableRow': {
          '.rdt_TableCell': {
            transition: 'all 0.25s ease',
            '.product': {
              alignItems: 'center',
              display: 'flex',
            },
            '.product-image': {
              borderRadius: 8,
              display: 'block',
              height: 48,
              marginRight: 16,
              overflow: 'hidden',
              position: 'relative',
              width: 48,
              'img': {
                display: 'block',
                left: '50%',
                maxHeight: '100%',
                maxWidth: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }
            },
          },
        },
      },
    },
    '.rdt_Pagination': {
      background: 'none',
      borderTop: 0,
      color: 'black',
      fontSize: 16,
      lineHeight: '28px',
      marginTop: 16,
    },
  });

  const cardViewStyles = css({
    '.rdt_Table': {
      backgroundColor: 'transparent',
      '.rdt_TableHead': {
        display: 'none',
        '.rdt_TableHeadRow': {
          '.rdt_TableCol': {
            display: 'none',
            ':first-of-type': {
              display: 'flex',
            },
          },
        },
      },
      '.rdt_TableBody': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '.rdt_TableRow': {
          border: 'none',
          borderRadius: 8,
          boxSizing: 'border-box',
          display: 'block',
          flexGrow: 0,
          flexShrink: 0,
          marginBottom: 20,
          marginRight: 20,
          overflow: 'hidden',
          padding: 16,
          position: 'relative',
          width: 'calc(25% - 15px)',
          ':nth-of-type(4n + 4)': {
            marginRight: 0,
          },
          ':hover': {
            border: 'none',
            outline: 'none',
          },
          '.rdt_TableCell': {
            display: 'block',
            padding: 0,
            '.product': {
              display: 'block',
              'a': {
                fontSize: 24,
                lineHeight: '38px',
              }
            },
            '.product-image': {
              height: 'auto',
              marginBottom: 16,
              marginRight: 0,
              width: '100%',
              ':before': {
                content: '""',
                display: 'block',
                paddingTop: '100%',
              },
            },
            ':first-of-type': {
              background: 'white',
              boxSizing: 'border-box',
              left: 0,
              padding: '12px 0 12px 12px',
              position: 'absolute',
              top: 0,
              width: 32,
              zIndex: 1,
              '.eds-inputs__checkbox': {
                margin: 0,
                padding: 0,
              }
            },
            ':last-of-type': {
              maxWidth: '9999px',
              textAlign: 'right',
            },
          },
        },
      },
    },
  });

  const tableClasses = cx({
    [tableWrapperStyles]: true,
    [cardViewStyles]: cards,
  });

  const tableStyles = {
    headCells: {
      style: {
        color: '#6A6A6A',
        fontSize: 16,
        lineHeight: '24px',
        padding: 16,
      },
    },
    cells: {
      style: {
        fontSize: 16,
        lineHeight: '24px',
        padding: 16,
      },
    },
  };

  const renderEmpty = () => {
    if (splash) {
      return <div className={cx(emptyStyles, 'eds-spacing--m-48')}>
        <Text className="eds-spacing--mb-8" font={"title-3"} as={"h2"}>{t('Get Started')}</Text>
        <Text className="eds-spacing--mb-24" font={"paragraph"} as={"p"}>{t('Start your search and select filters to return results.')}</Text>
      </div>
    }

    return <div className={cx(emptyStyles, 'eds-spacing--m-48')}>
      <Text className="eds-spacing--mb-8" font={"title-3"} as={"h2"}>{t('No Results Found')}</Text>
      <Text className="eds-spacing--mb-24" font={"paragraph"} as={"p"}>{t('Try modifing you search and filter criteria.')}</Text>
      <Button  variant="secondary">{t('Create Attributes')}</Button>
    </div>
  };

  const renderLoading = () => <div className={cx(emptyStyles, 'eds-spacing--m-48')}><Spinner size="large" /></div>;

  const handleSelection = ({ allSelected, selectedCount, selectedRows }) => {
    onSelection({allSelected, selectedCount, selectedRows});
  };

  return <div className={tableClasses}>
    <DataTable
      clearSelectedRows={clear}
      columns={columns}
      customStyles={tableStyles}
      data={data}
      expandableIcon={<CaretDown />}
      noDataComponent={renderEmpty()}
      onSelectedRowsChange={handleSelection}
      pagination
      paginationPerPage={20}
      paginationComponentOptions={{ noRowsPerPage: true }}
      paginationIconFirstPage={<CaretLeft />}
      paginationIconLastPage={<CaretRight />}
      paginationIconNext={<CaretRight />}
      paginationIconPrevious={<CaretLeft />}
      persistTableHead
      progressComponent={renderLoading()}
      progressPending={loading}
      selectableRows
      selectableRowsComponent={CustomCheckbox}
      selectableRowsNoSelectAll={true}
      sortIcon={<CaretDown />} />
  </div>
};

Table.propTypes = {
  cards: PropTypes.bool,
  clear: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onSelection: PropTypes.func,
  splash: PropTypes.bool,
};

const CustomCheckbox = React.forwardRef(({ onClick, ...other }, ref) => {
  return <Checkbox onClick={onClick} {...other}  />;
});

CustomCheckbox.displayName = 'CustomCheckbox';
CustomCheckbox.propTypes = {
  onClick: PropTypes.func,
};