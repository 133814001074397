import React, { useContext, useEffect } from 'react';

import { Typography, VariantType } from '@nike/admin-design-system-components/dist/index';
import { AppContext, useAppContext } from '../app/utils/context';

const Page = () => {
  const { appProps } = useAppContext();
  const { setAppProps } = useContext(AppContext);
  
  useEffect(() => {
    setAppProps({...appProps, section: 'Home'});
  }, []);

  return <>
    <Typography variant={VariantType.display1}>Welcome, {appProps.name}</Typography>
  </>
};

export default Page;