import merge from "deepmerge";

const base = {
    appFullName: "NTN 2 UI",
    appName: "NTN 2 UI",
    description: "Used for the NTN 2.0 React App",
    isProd: false,
    oAuth: {
      clientId: "nike.niketech.ntn2-ui",
      redirectUri: window.location.origin,
    },
  },
  dev = merge(base, {
    parameterApiUrl:
      "http://localhost:3000",
    planningApiUrl:
      "http://localhost:3000",
    weightsMeasuresApiUrl:
      "http://localhost:3000",
  }),
  prod = merge(base, {
    // parameterApiUrl:
    //   "https://xdc-consol-parameter-svc-prod.nodemanagement-test.nikecloud.com",
    // planningApiUrl:
    //   "https://xdc-consol-planning-svc-prod.nodemanagement-test.nikecloud.com",
    // weightsMeasuresApiUrl:
    //   "https://xdc-consol-parameter-svc-prod.nodemanagement-test.nikecloud.com",
    isProd: true,
  }),
  test = merge(base, {
    // parameterApiUrl:
    //   "https://xdc-consol-parameter-svc-test.nodemanagement-test.nikecloud.com",
    // planningApiUrl:
    //   "https://xdc-consol-planning-svc-test.nodemanagement-test.nikecloud.com",
    // weightsMeasuresApiUrl:
    //   "https://xdc-consol-parameter-svc-test.nodemanagement-test.nikecloud.com",
  });

let env = prod;
if (window.location.origin.includes("xdcconsol-dev.nikecloud.com")) {
  env = dev;
} else if (window.location.origin.includes("xdcconsol-test.nikecloud.com")) {
  env = test;
} else {
  env = dev;
}
const config = env;
export default config;